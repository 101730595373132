<template>
    <div class="image-fallback">
        <ImgOrFallback
            v-if="type === 'Image' && isImage"
            :src="url"
            :height=height
            :width=width
            :max-height=maxHeight
            :max-width=maxWidth
            :object-fit=objectFit
        />
        <i v-if="media.mediaClass === MediaClass.AUDIO" class="bi bi-microphone2" :class="{ size: !isMediaBadge }"  ></i>
        <i v-if="media.mediaClass === MediaClass.VIDEO" class="bi bi-film" :class="{ size: !isMediaBadge }" ></i>
        <i v-if="media.mediaClass === MediaClass.FILE && isMediaBadge" uk-icon="icon: file-text; ratio: 0.9" ></i>
        <i v-if="media.mediaClass === MediaClass.FILE && !isMediaBadge" uk-icon="icon: file-text; ratio: 2.3"  ></i>
        <i v-if="media.mediaClass === MediaClass.LIVE" class="bi bi-video-cam" :class="{ size: !isMediaBadge }" ></i>
        <i v-if="media.mediaClass === MediaClass.MODEL" class="bi bi-cube" :class="{ size: !isMediaBadge }"></i>
        <i v-if="media.mediaClass === MediaClass.IMAGE && type==='icon'" class="bi bi-image" :class="{ size: !isMediaBadge }" ></i>
        <i v-if="media.mediaClass === MediaClass.SUBTITLES && isMediaBadge" uk-icon="icon: comment; ratio: 1" ></i>
        <i v-if="media.mediaClass === MediaClass.SUBTITLES && !isMediaBadge" uk-icon="icon: comment; ratio: 2.2"></i>
    </div>
</template>

<script>
import ImgOrFallback from '@/components/images/ImageOrFallback';
import { MediaClass } from '@/utils/enums';

export default {
    components: { ImgOrFallback },
    props: {
        // The media to show an icon for.
        media: { type: Object, default: null },
        type: { type: String, default: 'Image' },
        isMediaBadge: { type: Boolean, default: false },
        height: {
            type: String,
            default:"40px"
        },
        "max-height":{
             type: String,
        },
        width: {
            type: String,
            default:"75px"
        },
        "max-width":{
            type: String,
        },
        "object-fit":{
            type:String
        }
    },
    computed: {
        isImage () {
            return this.media.mediaClass === MediaClass.IMAGE;
        },
        url () {
            return this.media?.file?.url;
        }
    },
    data () {
        return {
            MediaClass
        }
    }
};
</script>

<style lang="scss" scoped>
.size {
    font-size: 2rem;
}
.image-fallback .bi {
    font-weight: 600;
}
</style>