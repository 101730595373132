import { orderBy } from 'natural-orderby';

export function getTranscodeTypeFromMediaType ( mediaType, transcodeType = null ) {
    if ( isMediaTypeCLEARVR( mediaType ) )
        transcodeType = 'CLEARVR';
    else if ( isMediaTypeHLS( mediaType ) )
        transcodeType = 'HLS_ABR';
    else if ( isMediaTypeMP4( mediaType ) )
        transcodeType = 'LOWER_RESOLUTION_MP4S';

    return transcodeType;
}

export function getMediaTranscodeType ( media ) {
    const { mediaType, transcodeType } = media;

    return transcodeType || getTranscodeTypeFromMediaType( mediaType );
}

export function isMediaTranscodeTypeCLEARVR ( media ) {
    return getMediaTranscodeType( media ) === 'CLEARVR';
}

export const mediaTypeRegex = new RegExp( `(${[
    // Apple sanctioned
    'application/vnd.apple.mpegurl',
    // Apple sanctioned for backwards compatibility
    'audio/mpegurl',
    // Very common
    'audio/x-mpegurl',
    // Very common
    'application/x-mpegurl',
    // Included for completeness
    'video/x-mpegurl',
    'video/mpegurl',
    'application/mpegurl'
].join( '|' )})`, 'i' );

export const isMediaTypeHLS = mime => mediaTypeRegex.test( mime );

export const isMediaTypeMP4 = mime => /video\/mp4/i.test( mime );

export const isMediaTypeCLEARVR = mime => /application\/clearVR/i.test( mime );

export function getMediaResolutionLabel ( media, defaultLabel ) {
    const { width, height } = media.resolution || {};

    return ( width || height ) ? `${width} × ${height}` : defaultLabel;
}

export function isBundle ( bundle ) {
    return bundle?.__typename === 'MediaBundle';
}

export function getMediaSizeBytes ( media, defaultSize = 0 ) {
    return media?.size || defaultSize;
}

export function sortBy ( arr, property, sortOrder ) {
    return orderBy( arr, property, [ sortOrder ]);
}

export function add ( arr, property = 'bits' ) {
    return arr.reduce( ( a, b ) => a + b[property], 0 );
}